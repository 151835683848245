<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <accion-ejec-form
          v-if="parteTrabajoMatsist && parteTrabajoMatsist.material_sistema"
          :id="null"
          :ffin-garantia="parteTrabajoMatsist.material_sistema.ffin_garantia"
          :idparte-trabajo-matsist="routeParams.idparte_trabajo_matsist"
          :factura-junto-revision-y-retimbrado="parteTrabajoMatsist.tsubfamilia.factura_junto_revi_retim"
          :has-perm-modificar-material-facturable="hasViewPerm(permissions.partes.modificarMaterialFacturable)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import AccionEjecForm from '../components/AccionEjecForm'
import Data from './AccionEjecAddData'

export default {
  components: {
    AccionEjecForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      parteTrabajoMatsist: {},
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir acción'
      this.parteTrabajoMatsist = await Data.selectParteTrabajoMatSist(this, this.routeParams.idparte_trabajo_matsist)
    },
    async submitForm () {
      await Data.insert(
        this,
        this.formData,
        this.parteTrabajoMatsist.orden_trabajo_matsist.idorden_trabajo_matsist,
        this.routeParams.idparte_trabajo
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.parteTrabajoMatsist.parte_trabajo_matsist.idparte_trabajo_matsist)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.parteTrabajoMatsist.material_sistema.idsubsis)
      this.$appRouter.go(-1)
    },
  },
}
</script>
